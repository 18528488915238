<template>
  <div class="seaDetail-container">
    <el-drawer
      class="seaDetail-drawer"
      title="订单详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div
        v-loading="loading2"
        element-loading-text="数据较多，拼命加载中..."
        class="drawer-container"
      >
        <div class="drawer-left">
          <div class="first">
            <div class="title">
              <div style="display: flex;align-items: center;">
                <span class="first-tit">{{
                  tableRow.disOrderCode || "--"
                }}</span>
                <!-- <span class="fail">{{ tableRow.failFlagName }}</span> -->
                <span :class="tableRow.settleFlagName == '待结算' ? 'deleteflag1' : (tableRow.settleFlagName == '已结算' ? 'deleteflag2':tableRow.settleFlagName == '结算中' ? 'deleteflag3':'deleteflag4')">{{ tableRow.settleFlagName }}</span>
              </div>
              <el-button
                v-if="tableRow.deleteFlag == 0"
                type="primary"
                class="common-screen-btn"
                @click="
                  () => {
                    start();
                  }
                "
                >发起出账</el-button
              >
            </div>
            <div class="first-con1">
              <span class="con-margin"
                >公司名称：{{ tableRow.companyName || "--" }}</span
              >
              <span class="con-margin"
                >订单名称：{{ tableRow.disOrderName || "--" }}</span
              >
            </div>
            <div class="first-con2">
              <span class="con-margin">业务类型：{{ tableRow.typeName }}</span>
              <span class="con-margin con2"
                >订单服务时间：{{
                  tableRow.startTime + "~" + tableRow.endTime || "--"
                }}</span
              >
            </div>
            <div class="first-con3">
              <span class="con-margin"
                >派单总金额：{{ (tableRow.totalPrice&&(tableRow.totalPrice).toFixed(2)) || "--" }}</span
              >
              <span class="con-margin"
                >加班费：{{ (tableRow.overtimePrice&&(tableRow.overtimePrice).toFixed(2)) || "--" }}</span
              >
              <span class="con-margin"
                >服务扣款：{{ (tableRow.serviceCharge&&(tableRow.serviceCharge).toFixed(2)) || "--" }}</span
              >
            </div>
            <div class="first-con4">
              <span class="con-margin"
                >结算金额：{{ (tableRow.settlePrice&&(tableRow.settlePrice).toFixed(2)) || "--" }}</span
              >
              <span class="con-margin"
                >已结算金额：{{ (tableRow.paySettlePrice&&(tableRow.paySettlePrice).toFixed(2)) || "--" }}</span
              >
              <span class="con-margin"
                >未结算金额：{{ (tableRow.unPaySettlePrice&&(tableRow.unPaySettlePrice).toFixed(2)) || "--" }}</span
              >
            </div>
            <div class="first-con5">
              <span class="con-margin2"
                >备注：{{ tableRow.remark || "--" }}</span
              >
            </div>
          </div>
          <!-- 附件 -->
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">
                  上传附件<input
                    @input="
                      (e) => {
                        uploadFile(e);
                      }
                    "
                    class="uploadBtn-input"
                    type="file"
                  />
                </div>
              </div>
            </div>
            <div v-if="tableRow.fileList && tableRow.fileList.length" class="fileList-container">
              <div
                v-for="(item, index) in tableRow.fileList"
                :key="index"
                class="fileItem"
              >
                <div class="fileItem-name">
                  <common-picture
                    :fileUrl="item.filedUrl"
                    :fileType="
                      Config.fileType.imgType.includes(
                        item.filedUrl.split('.')[item.filedUrl.split('.').length - 1]
                      )
                        ? 'imgText'
                        : 'file'
                    "
                    :fileName="item.filedName"
                  ></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.filedUrl);
                      }
                    "
                  >
                    下载
                  </div>
                  <el-popconfirm
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <el-button type="text" size="small" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div
              v-if="!tableRow.fileList || !tableRow.fileList.length"
              class="fileList-container"
            >
              <div class="fileItem">
                暂无附件
              </div>
            </div>
          </div>

          <el-tabs class="tabsDetail" v-model="activeTab">
            <el-tab-pane label="结算信息" name="1">
              <el-table
                class="common-table"
                :data="tableRow.settleList"
                :fit="true"
              >
                <el-table-column
                  prop="collectionName"
                  label="收款人"
                  width="90px"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.settleFlag == 4"
                      type="text"
                      size="small"
                      class="del"
                      @click="() => stmtShow('select', scope.row)"
                      >{{ scope.row.collectionName }}</el-button
                    >
                    <el-button
                      v-else
                      type="text"
                      size="small"
                      @click="() => stmtShow('select', scope.row)"
                      >{{ scope.row.collectionName }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="settlePrice" label="金额">
                </el-table-column>
                <el-table-column label="结算类型">
                  供应商结算
                </el-table-column>
                <el-table-column
                  prop="settleWayName"
                  label="结算方式"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="collectionAccount"
                  label="收款账户"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="phone"
                  label="联系方式"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="结算状态">
                  <template slot-scope="scope">
                    <span
                      :class="
                        scope.row.settleFlag == 1
                          ? 'deleteflag1'
                          : scope.row.settleFlag == 2
                          ? 'deleteflag3'
                          : scope.row.settleFlag == 3
                          ? 'deleteflag2'
                          : 'deleteflag4'
                      "
                      >{{ scope.row.settleFlagName }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="出账信息" name="2">
              <el-table
                class="common-table"
                :data="tableRow.finList"
                :fit="true"
              >
                <el-table-column
                  prop="collectionName"
                  label="流水编号"
                  width="110px"
                >
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.deleteFlag == 1"
                      type="text"
                      size="small"
                      class="del"
                      @click="() => incomeShow(scope.row)"
                      >{{ scope.row.code }}</el-button
                    >
                    <el-button
                      v-else
                      type="text"
                      size="small"
                      @click="() => incomeShow(scope.row)"
                      >{{ scope.row.code }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="金额">
                </el-table-column>
                <el-table-column label="出账类型">
                  供应商结算
                </el-table-column>
                <el-table-column
                  prop="recordTypeName"
                  label="出账方式"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="transactionName"
                  label="收款人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="transactionAccount"
                  label="收款账户"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="transactionPhone"
                  label="联系方式"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createName"
                  label="出账人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="recordTime"
                  label="出账时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="出账状态">
                  <template slot-scope="scope">
                    <span
                      :class="
                        scope.row.deleteFlag == 0
                          ? 'deleteflag3': 'deleteflag4'"
                      >{{ scope.row.deleteFlag == 0? '已出账':'已作废' }}</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="3">
              <div class="detail-content">
                <div class="DisableEdit">
                  通知人：
                  <span class="common-clr-0a6">{{
                    (tableRow && tableRow.noticeName) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  通知人所属部门：
                  <span class="common-clr-0a6">{{
                    (tableRow && tableRow.noticeDepartName) || "--"
                  }}</span>
                </div>
                <br/>
                <div class="DisableEdit">
                  创建人：
                  <span class="common-clr-0a6">{{
                    (tableRow && tableRow.createName) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  创建时间： <span class="common-clr-0a6">{{ (tableRow && tableRow.createTime) || "--" }}</span>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        
        <!-- 工作日志 -->
        <div class="drawer-right">
          <el-tabs style="height: 100%;display: flex;flex-direction: column;" v-model="activeName">
            <el-tab-pane label="工作记录" name="appeal">
              <div>
                <el-input :autosize="{ minRows: 6, maxRows: 6 }" type="textarea" v-model="recordContent" placeholder="工作小计"></el-input>
                <div>
                  <div class="fileItem" v-for="(item, index) in filesList" :key="index">
                    <div><common-picture :fileUrl="item.fileUrl" :fileType="Config.fileType.imgType.includes(item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]) ? 'imgText' : 'file'" :fileName="item.fileName"></common-picture></div>
                    <div class="common-blue-btn" @click="() => deleteWorkFile(item.fileId)">删除</div>
                  </div>
                </div>
                <div class="appeal-commit">
                  <div>
                    <div class="addFileBtn">
                      <img style="margin-right: 8px" src="../../../assets/images/add_file_icon.svg" />添加附件
                      <input class="addFileInput" :multiple="false" @input="chooseFile" type="file" />
                    </div>
                    <div style="font-size: 12px; color: #ccc"> 上传文件及图片，大小不超过50M </div>
                  </div>
                  <el-button @click="() => {addWorkLog()}" class="common-screen-btn" type="primary" >发布</el-button>
                </div>
              </div>
              <common-work-log :followRecordList="followRecordList"></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>

    <!-- 新增弹框 -->
    <el-dialog
      v-dialogDrag
      title="新增出账"
      :visible.sync="dialogVisible"
      width="520px"
      :append-to-body="true"
      :before-close="
        () => {
          close();
        }
      "
    >
      <el-form
        ref="form"
        :rules="rulesIncome"
        :model="seeMoreForm"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="出账金额" prop="amount">
          <el-input v-model.trim="seeMoreForm.amount" class="ipt2"></el-input>元
        </el-form-item>
        <el-form-item label="出账类型" prop="expenditureType">
          <el-select
            v-model="seeMoreForm.expenditureType"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出账方式" prop="recordType">
          <el-select
            v-model="seeMoreForm.recordType"
            placeholder="请选择"
            class="ipt"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款人" prop="transactionName">
          <el-input
            v-model.trim="seeMoreForm.transactionName"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="收款账户">
          <el-input
            v-model.trim="seeMoreForm.transactionAccount"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input
            v-model.trim="seeMoreForm.transactionPhone"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="出款时间" prop="recordTimeString">
          <el-date-picker
            v-model="seeMoreForm.recordTimeString"
            type="date"
            placeholder="选择日期"
            class="ipt"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知人" prop="noticeId">
          <el-select
            v-if="noticeList && noticeList.length"
            class="ipt"
            v-model="seeMoreForm.noticeId"
            placeholder="请选择通知人"
            multiple
          >
            <el-option
              v-for="item in noticeList"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="seeMoreForm.remark"
            type="textarea"
            :rows="3"
            class="ipt"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="orderFile-container">
            <div class="orderFileBtn">
              上传附件
              <input
                class="orderFile-input"
                type="file"
                @input="
                  (e) => {
                    orderFile(e);
                  }
                "
              />
            </div>
            上传文件及图片，大小不超过50MB
          </div>
          <div class="fileList-container">
            <div
              v-for="(item, index) in fileList"
              :key="index"
              class="fileList-item"
            >
              <div class="item-name">
                <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[
                        item.fileUrl.split('.').length - 1
                      ]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
              </div>
              <div
                @click="
                  () => {
                    deleteFlie(index);
                  }
                "
                class="deleteBtn"
              >
                删除
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              sendRequest();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <settlement ref="settlement" :drawerSettlement="drawerSettlement" @handleCloseSettlement="handleCloseSettlement"></settlement>

    <!-- 出账详情 -->
    <el-dialog
      v-dialogDrag
      title="出账详情"
      :visible.sync="settleVisible"
      width="520px"
      :append-to-body="true"
      :before-close="
        () => {
          this.settleVisible = false
        }"
    >
    <div class="income-one">
        <div>
          <span class="one-tit">出账金额：</span
          >{{ settleInfo.amount || "--" }} <span class="one-unit">元</span>
        </div>
        <div>
          <span class="one-tit">出账类型：</span
          >供应商结算
        </div>
        <div>
          <span class="one-tit">出账方式：</span
          >{{ settleInfo.recordTypeName || "--" }}
        </div>
        <div>
          <span class="one-tit">收款人：</span
          >{{ settleInfo.transactionName || "--" }}
        </div>
        <div>
          <span class="one-tit">收款账户：</span
          >{{ settleInfo.transactionAccount || "--" }}
        </div>
        <div>
          <span class="one-tit">联系方式：</span
          >{{
            settleInfo.transactionPhone }}
        </div>
        <div>
          <span class="one-tit">出款时间：</span>{{ settleInfo.recordTime || "--" }}
        </div>
        <div>
          <span class="one-tit">通知人：</span>{{ settleInfo.noticeName || "--" }}
        </div>
        <div>
          <span class="one-tit">备注：</span>{{ settleInfo.remark || "--" }}
        </div>
        <div style="display: flex;align-items: baseline;">
          <span class="one-tit2 one-bottom box">附件：</span>
          <div class="filelist">
            <div
              v-for="(item, i) in settleInfo.files"
              :key="i"
              style="display: flex;align-items: center;"
            >
              <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[item.fileUrl.split('.').length - 1]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
                <el-button
                type="text"
                class="down"
                @click="
                  () => {
                    download(item.fileUrl);
                  }"
                >下载</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fileDelete,fileUpload,addWorkLog,orderWorkLog,insertFinancialFlow,selectFinancialById } from "../../../service/money";
import { upload } from "../../../service/upload.js";
import {
  DateTransform,
  Config,
  rulePrice,
  tableColumn
} from "../../../utils/index.js";
import { orderNoticeList } from "../../../service/customer.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue";
import CommonPicture from "../../../components/common/commonPicture.vue";
import Settlement from "../../assignOrder/children/settlement.vue"

export default {
  components: { CommonWorkLog, CommonPicture,Settlement },
  props: [
    "drawer",
    "tableRow", //传过来一行数据
    "customerInfo",
    "adminInfo",
    "chanceFollowList",
    "custDict",
    "loading",
    "childid"
  ],
  data() {
    const maxMoney = (rule, value, callback) => {
      if (value) {
        if (value>this.tableRow.unPaySettlePrice) {
          callback(new Error("出账金额不能大于未结算金额"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      DateTransform,
      Config,
      tableColumn,
      activeTab: "1", // tabs
      fileList: [],
      activeName: "appeal", // tabs
      loading2: this.loading,
      dialogVisible: false,
      newAddVisible: false, //新增弹框
      settleVisible: false, //出账详情
      settleInfo:{},
      seeMoreForm: {
        amount: "",
        expenditureType: "supplierSettlement",
        recordType: "",
        transactionName: "",
        transactionAccount: "",
        transactionPhone: "",
        recordTimeString: "",
        remark: "",
        noticeId: []
      },
      rulesIncome: {
        amount: [
          { required: true, message: "不能为空", trigger: "blur" },
          {
            validator: rulePrice,
            type: "number",
            trigger: "blur"
          },
          {
            validator: maxMoney,
            trigger: "blur"
          }
        ],
        expenditureType: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        transactionName: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        recordType: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        recordTimeString: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        noticeId: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
      },
      options: [
        {
          value: "supplierSettlement",
          label: "供应商结算"
        }
      ],
      options2: [
        {
          value: "bankCard",
          label: "银行卡"
        },
        {
          value: "alipay",
          label: "支付宝"
        },
        {
          value: "wechat",
          label: "微信"
        },
        {
          value: "cash",
          label: "现金"
        },
        {
          value: "other",
          label: "其他"
        }
      ],
      ruleForm: {
        textarea: ""
      },
      rules: {
        textarea: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      noticeList: [], // 通知人下拉
      flowTableData: "",
      recordContent: '', // 工作小计
      filesList: [],  // 工作小计文件上传
      followRecordList: [], // 工作日志
      drawerSettlement: false, // 结算详情弹窗
      noticeNameList: []
    };
  },
  methods: {
    // 通知人
    async orderNoticeList() {
      let resData = (await orderNoticeList()).data;
      this.noticeList = resData;
    },
    // 删除附件
    async deleteFile(item) {
      this.$emit("loadFun", true);
      let params = {
        disOrderNo: this.tableRow.disOrderNo,
        filedId: item.filedId,
        filedUrl: item.filedUrl,
        fileName: item.filedName
      };
      const res = await fileDelete(params);
      if (res.code != 200) {
        return this.$message.error("删除失败");
      }
      this.$message.success("删除成功");
      this.$emit("getpayout", this.childid);
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    close() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleClick() {
      console.log(this.activeName);
    },
    // 发起收款
    start() {
      this.dialogVisible = true;
      this.seeMoreForm = {
        amount: Number(this.tableRow.unPaySettlePrice).toFixed(2),
        expenditureType: "supplierSettlement",
        recordType: this.tableRow.settleList[0].settleWay,
        transactionName: this.tableRow.settleList[0].collectionName,
        transactionAccount: this.tableRow.settleList[0].collectionAccount,
        transactionPhone: this.tableRow.settleList[0].phone,
        recordTimeString: "",
        remark: "",
        noticeId: []
      },
      this.orderNoticeList()
    },
    download(url) {
      window.location.href = url;
    },
    async uploadFile(e) {
      // 上传附件
      this.$emit("loadFun", true);
      let file = e.target.files[0];
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.$emit("loadFun", false);
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.$emit("loadFun", false);
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      // 上传接口
      const res = await fileUpload({
        disOrderNo: this.tableRow.disOrderNo,
        filedId: resData.id, 
        filedUrl: resData.filename, 
        fileName: fileName
      });
      if (res.code != 200) {
        return this.$message.success("上传失败");
      }
      this.$message.success("上传成功");
      e.target.value = "";
      this.$emit("getpayout", this.childid);
    },
    async addWorkLog() { // 工作记录发布按钮
      let filesList = [...this.filesList]
      let filesUrl = []
      let filesId = []
      let filesName = []
      filesList.map((item) => {
        filesUrl.push(item.fileUrl)
        filesId.push(item.fileId)
        filesName.push(item.fileName)
      })
      let params = {
        disOrderNo: this.tableRow.disOrderNo,
        content: this.recordContent,
        fileIds: filesId.join(','),
        filedName: filesName.join(','),
        filedUrl: filesUrl.join(','),
      };
      this.loading2 = true
      let { code } = await addWorkLog(params)
      this.loading2 = false
      if (code == 200) {
        this.orderWorkLog(this.tableRow.disOrderNo)
        this.filesList = []
        this.recordContent = "";
        this.$message.success("添加成功");
      }
    },
    deleteWorkFile (id) { // 删除工作日志文件
      let filesList = [...this.filesList]
      this.filesList = filesList.filter((item)=>{
        return item.fileId != id
      })
    },
    async chooseFile(e) { // 添加工作日志文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      this.loading2 = true
      let resData = (await upload({ file: file })).data;
      this.loading2 = false
      let fileItem = {
        fileType: "",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename,
      };
      if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
        fileItem.fileType = "img";
      }
      this.filesList.push(fileItem);
    },
    async orderWorkLog(id) { // 工作日志
      let { data } = await orderWorkLog({realtionId: id})
      if (data && data.length) {
        data.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value)
          } else {
            item.value = []
          }
        })
      }
      this.followRecordList = [...data]
    },
    stmtShow (type, row) { // 发起结算传值 type: (launch: 发起结算， select： 查看详情)  row: 查看详情的数据
        row.totalPrice = this.tableRow.totalPrice
        this.$refs.settlement.getDetailInfo(row, type)
      this.drawerSettlement = true
    },
    handleCloseSettlement () { // 发起结算关闭子组件
      this.drawerSettlement = false
    },
    //根据id查询 出账详情 进账详情
    async incomeShow(row){
      this.settleVisible = true
      const { data } = await selectFinancialById({id: row.id,flowType: 'expenditure'})
      this.settleInfo = data
    },
    deleteFlie(i) {
      let fileList = [...this.fileList];
      fileList = fileList.filter((item, index) => {
        return i != index;
      });
      this.fileList = fileList;
    },
    // 出账上传文件
    async orderFile(e) {
      this.loading2 = true;
      let fileList = [...this.fileList];
      let file = e.target.files[0];
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.loading2 = false;
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = fileName.split(".")[fileName.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.loading2 = false;
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      fileList.push({ fileName: fileName, fileType: resData.mimeType, fileUrl: resData.filename });
      this.fileList = fileList;
      e.target.value = "";
      this.loading2 = false;
    },
    // 确认按钮发请求
    async sendRequest() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          // 发送请求
          let noticeIds = this.seeMoreForm.noticeId
          noticeIds.forEach((item)=> {
            if(item){
              this.noticeList.forEach((item2)=>{
                if(item == item2.id){
                  this.noticeNameList.push(item2.nickName)
                }
              })
            }
          })
          let params = { ...this.seeMoreForm };
          if (params.noticeId && params.noticeId.length) {
            params.noticeId = params.noticeId.join(",");
          } else {
            delete params.noticeId;
          }
          if(this.noticeNameList&&this.noticeNameList.length){
            params.noticeName = this.noticeNameList.join(",")
          }else{
            delete this.noticeNameList
          }
          let fileList = [...this.fileList];
          params.fileUrls = fileList;
          if (params.fileUrls.length == 0) {
            delete params.fileUrls;
          }
          params.disOrderNo = this.tableRow.disOrderNo
          params.settleId = this.tableRow.settleId
          const res = await insertFinancialFlow(params);
          console.log(res);
          if (res.code != 200) {
           return this.$message.error("操作失败！");
          }
          this.dialogVisible = false
          this.$message.success("操作成功！");
          this.fileList = [];
          this.$emit("getpayout", this.childid);
          this.$refs.form.resetFields();
        } else {
          this.$message.error("请填写正确信息")
          return false;
        }
      });
    },
  }
};
</script>
<style lang="less" scoped>
.seaDetail-drawer {
  .fail{
    margin: 0 32px;
    color: rgba(24, 144, 255, 100);
    font-size: 14px;
  }
  .del {
  color: #ccc;
  text-decoration: line-through;
}
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .borderLine {
    position: relative;
    width: 100%;
    margin-top: 14px;
    &::after {
      content: " ";
      display: block;
      position: absolute;
      background: #eaeaeb;
      height: 1px;
      width: 120%;
      left: -10%;
      top: 0;
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      .appeal-commit {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        align-items: center;
        padding-bottom: 10px;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
      
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .detail-tit-info {
        font-size: 18px;
      }
      .hoverEdit {
        display: inline-block;
        width: 333px;
        font-size: 18px;
        padding: 6px 12px;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .edit-input-container {
        display: inline-block;
        width: 303px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input {
        width: 220px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
        .cancel {
          cursor: pointer;
        }
        /deep/ .el-input__inner,
        .el-input-group__append {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input-group__append {
          height: 30px;
          line-height: 30px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 303px;
        height: 21px;
        padding: 6px 12px;
        font-size: 12px;
        color: #999;
        margin-right: 16px;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-top: 16px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
          }
        }
      }
    }
    .data-content {
      .detail-content {
        margin-top: 16px;
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
.dialog-input {
  width: 320px !important;
}
.data-title {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #000000a6;
  border-bottom: 1px dashed #eaeaeb;
  margin-top: 16px;
}
.first {
  padding-bottom: 50px;
  border-bottom: 1px solid #eaeaeb;
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    .first-tit {
      font-size: 18px;
      margin-right: 32px;
    }
  }

  .tit-btn {
    display: inline-block;
    width: 73px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 11px;
  }
  .con-margin {
    display: inline-block;
    // margin-right: 68px;
    width: 268px;
    color: #666;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .con2{
    width: 500px;
  }
  .con-margin2 {
    color: #666;
    font-size: 14px;
  }
  .first-con1,
  .first-con2,
  .first-con3,
  .first-con4,
  .first-con5 {
    margin-top: 16px;
    display: flex;
    align-items: baseline;
  }
}

.baseinfo {
  display: inline-block;
  width: 422px;
  margin-top: 16px;
  color: #666;
  font-size: 12px;
}
.upload-container {
  margin: 32px 0;
  .fileList-container {
    border: 1px solid #ebeef5;
    margin-top: 12px;
    border-radius: 4px;
    .fileItem {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      border-bottom: 1px solid #ebeef5;
      height: 74px;
      line-height: 74px;
      align-items: center;
      .fileItem-btn-Container {
        display: flex;
        .fileItemBtn {
          margin-right: 16px;
          color: #1890ff;
          cursor: pointer;
        }
      }
    }
  }
  .upload-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999999;
    .tip {
      font-size: 12px;
    }
    .upload-title {
      font-size: 18px;
      color: #101010;
    }
    .uploadBtn-container {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #1890ff;
      color: #1890ff;
      font-size: 14px;
      background: #fff;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      margin-left: 16px;
      position: relative;
      .uploadBtn-input {
        width: 100px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        font-size: 0;
      }
    }
  }
}
.ipt {
  width: 100% !important;
}
.ipt2 {
  width: 92% !important;
  margin-right: 10px;
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      cursor: pointer;
      font-size: 0;
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
.deleteflag1 {
  //dai结算
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgba(255, 77, 79, 0.5);
  color: #fe4c4e;
}
.deleteflag2 {
  //yi结算
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgba(24, 144, 255, 50);
  color: #1890ff;
}
.deleteflag3 {
  //结算中
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #66cc1f;
  color: #66cc1f;
}
.deleteflag4 {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
/deep/.el-tab-pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/deep/ .el-drawer__open .el-drawer.rtl {
      width: 1656px !important;
  }
  /deep/ .el-drawer__body {
      padding: 0;
  }
  /deep/ .el-tabs__content {
      padding: 0 8px 0 16px;
  }
.income-one{
  div:not(:first-child) {
    margin-top: 16px;
  }
  .one-tit{
    display: inline-block;
    width: 70px;
  }
}
</style>
<style lang="less">
.el-popover {
  .el-popconfirm__action {
    margin-top: 12px;
    .el-button--mini {
      height: 26px !important;
      line-height: 10px;
      margin-left: 16px;
    }
  }
}
</style>
